import { createRouter, createWebHistory } from 'vue-router';
import FormularioMaridaje from './components/FormularioMaridaje.vue';
import ResultadoMaridaje from './components/ResultadoMaridaje.vue';

const routes = [
  {
    path: '/',
    name: 'formulario',
    component: FormularioMaridaje
  },
  {
    path: '/resultado',
    name: 'resultado',
    component: ResultadoMaridaje,
    props: true
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
