<template>
  <header class="text-center mb-4">
    <div class="header-kroon">
      <h1 class="text-light">MARIDADOR</h1>
    </div>
    <img src="../assets/logo.png" alt="Logo Kroon" class="mb-2" width="150px"/> <!-- Direct path to logo -->
  </header>
</template>

<script>
export default {
  methods: {
    redirigirFormulario() {
      this.$router.push({ name: 'formulario' });  // Redirige al formulario al hacer clic en el header
    }
  },  
  props: {
    showButton: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Selecciona tu queso',
    },
    onButtonClick: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.text-light {
  color: #fff; /* Color del texto */
}
</style>