import { createApp } from 'vue';
import App from './App.vue';
import store from './store';  // Asegúrate de importar el store
import router from './router'; // Asegúrate de importar el router
import './assets/global.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faCrown);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);
app.use(store);  // Asegúrate de usar Vuex con `app.use(store)`
app.use(router); // Asegúrate de usar el router
app.mount('#app');
