import { createStore } from 'vuex'; // Importar createStore de Vuex

export default createStore({
  state: {
    resultado: null,   // Aquí guardaremos el resultado del maridaje
    ingredientes: null  // Aquí guardaremos los ingredientes seleccionados
  },
  mutations: {
    setResultado(state, resultado) {
      state.resultado = resultado;
    },
    setIngredientes(state, ingredientes) {
      state.ingredientes = ingredientes;
    },
    resetDatos(state) {
      state.resultado = null;
      state.ingredientes = null;
    }
  },
  actions: {
    actualizarResultado({ commit }, resultado) {
      commit('setResultado', resultado);
    },
    actualizarIngredientes({ commit }, ingredientes) {
      commit('setIngredientes', ingredientes);
    },
    limpiarDatos({ commit }) {
      commit('resetDatos');
    }
  },
  getters: {
    resultado: state => state.resultado,
    ingredientes: state => state.ingredientes
  }
});
