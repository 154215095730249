<template>
  <div class="container mt-5">
    <HeaderMaridador />
    <p class="text-light text-kroon">
      Corona tus ocasiones con las mejores tablas de quesos Kroon.
      Te ayudamos a crear combinaciones increíbles y sorprender a tus invitados.
      ¡Kroon creado para ti!
    </p>

    <form @submit.prevent="calificaMiCreacion" class="form-group">
      <div class="mb-4">
        <label for="quesoSeleccionado" class="form-label">Selecciona tu queso favorito</label>
        <select v-model="quesoSeleccionado" id="quesoSeleccionado" class="form-select">
          <option disabled value="">Selecciona un queso Kroon</option>
          <option value="Gouda">Gouda</option>
          <option value="Edam">Edam</option>
          <option value="Maasdam">Maasdam</option>
          <option value="Procesado-ahumado-natural">Procesado ahumado natural</option>
          <option value="Procesado-ahumado-finas-hierbas">Procesado ahumado finas hierbas</option>
        </select>
      </div>

      <p class="text-center mb-4">Ahora selecciona los ingredientes que deseas maridar en tu tabla</p>

      <p class="indicador">Agrega algo crujiente...</p>
      <input v-model="crujiente" class="form-control" placeholder="Galleta salada, nueces de la india, etc..." value="Galleta salada"/>
      <p class="indicador">Agrega algo dulce...</p>
      <input v-model="dulce" class="form-control" placeholder="Uvas, higos, mermelada, etc..." value="Uvas" />
      <p class="indicador">Agrega algo vegetal...</p>
      <input v-model="vegetal" class="form-control" placeholder="Tomate cherry, aceitunas, apio, etc..." value="Tomate cherry" />
      <p class="indicador">Agrega charcutería...</p>
      <input v-model="charcuteria" class="form-control" placeholder="Rollitos de jamón serrano, salami, chorizo, etc..." value="Rollitos de jamón serrano" />
      <p class="indicador">Agrega la bebida...</p>
      <input v-model="bebida" class="form-control" placeholder="Vino Pinot Noir, cerveza clara, tequila, etc..." value="Vino Pinot Noir" />
      <p class="indicador">¿Quieres agregar algo más?</p>
      <input v-model="extras" class="form-control" placeholder="Echa a volar la creatividad" value="Nueces" />

      <!-- Botón con spinner -->
      <button type="submit" class="btn btn-warning btn-block" :disabled="cargando">
        <span v-if="cargando" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span v-if="!cargando">Califica mi creación</span>
      </button>
    </form>
  </div>
</template>

<script>
import HeaderMaridador from './HeaderMaridador.vue';

export default {
  components: {
    HeaderMaridador,
  },
  mounted() {
    // Cargar los ingredientes guardados en el store, si existen
    const ingredientesGuardados = this.$store.getters.ingredientes;
    
    if (ingredientesGuardados) {
      this.quesoSeleccionado = ingredientesGuardados.queso || '';
      this.crujiente = ingredientesGuardados.crujiente || '';
      this.dulce = ingredientesGuardados.dulce || '';
      this.vegetal = ingredientesGuardados.vegetal || '';
      this.charcuteria = ingredientesGuardados.charcuteria || '';
      this.bebida = ingredientesGuardados.bebida || '';
      this.extras = ingredientesGuardados.extras || '';
    }
  },
  data() {
    return {
      crujiente: '',
      dulce: '',
      vegetal: '',
      charcuteria: '',
      bebida: '',
      quesoSeleccionado: '',
      extras: '',
      cargando: false // Variable para manejar el estado de "cargando"
    };
  },
  methods: {
  calificaMiCreacion() {
    this.cargando = true;

    const ingredientes = {
      queso: this.quesoSeleccionado,
      crujiente: this.crujiente,
      dulce: this.dulce,
      vegetal: this.vegetal,
      charcuteria: this.charcuteria,
      bebida: this.bebida,
      extras: this.extras
    };

    fetch(`${process.env.VUE_APP_BACKEND_URL}/califica`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(ingredientes)
    })
      .then(response => response.json())
      .then(data => {
        const resultadoLimpio = data.resultado
          .replace(/```json/g, '')
          .replace(/```/g, '');

        // Guardar en el store de Vuex
        this.$store.dispatch('actualizarIngredientes', ingredientes);
        this.$store.dispatch('actualizarResultado', JSON.parse(resultadoLimpio));

        // Navegar a la página de resultados sin usar query params
        this.$router.push({ name: 'resultado' });
      })
      .catch(error => {
        console.error('Error:', error);
      })
      .finally(() => {
        this.cargando = false;
      });
  }
}
};
</script>

<style scoped>
.spinner-border {
  margin-right: 5px;
}

/* Asegúrate de que el contenedor del formulario tenga position relative */
.form-group {
  position: relative;
}
</style>
