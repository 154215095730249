<template>
  <div class="container mt-5">
    <HeaderMaridador />
    <div class="container text-center">
      <h1>Resultado de tu maridaje</h1>
      <div class="ingredientes-seleccionados">
        <p><strong>{{ descripcion }}</strong></p>
      </div>

      <!-- Desplegar 5 coronas, algunas opacas y otras transparentes -->
      <div class="coronas">
        <img 
          v-for="index in 5" 
          :key="index" 
          :src="require(index <= coronas ? '../assets/corona.png' : '../assets/corona-transp.png')" 
          alt="Corona" 
          class="coronas-icon"
          style="max-height: 50px;"
        />
      </div>

      <!-- Mostrar la calificación y resumen -->
      <h2 class="mt-4">{{ calificacion }}/100 - {{ rango }}</h2>
      <p>{{ resumen }}</p>

      <!-- Enlace para abrir modal de recomendaciones -->
      <a @click.prevent="mostrarModalRecomendaciones" class="recomendaciones-link">Ver recomendaciones</a>

      <!-- Modal de recomendaciones -->
      <div v-if="mostrarRecomendaciones" class="modal-backdrop">
        <div class="modal-content">
          <!-- Botón de cierre como cruz "X" -->
          <span @click="cerrarModalRecomendaciones" class="close-button">&times;</span>
          <h2>Recomendaciones</h2>
          <div class="modal-body">
            <ul>
              <li v-for="(sugerencia, index) in sugerencias" :key="index">{{ sugerencia }}</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Botón de generar imagen con IA con spinner y mensajes alternos -->
      <button @click="generarImagenIA" class="btn btn-warning mt-3" :disabled="cargandoImagen">
        <span v-if="cargandoImagen" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span v-if="cargandoImagen" style="padding-left: 10px; font-size: 0.8em">{{ mensajeActual }}</span>
        <span v-else>{{ imagenGenerada ? 'Volver a generar' : 'Generar imagen con IA' }}</span>
      </button>

      <!-- Botones de navegación -->
      <button @click="editarMaridaje" class="btn btn-warning mt-3">
        <i class="fas fa-arrow-left"></i> Editar maridaje
      </button>

      <!-- Modal de imagen generada -->
      <div v-if="mostrarImagenGenerada" class="modal-backdrop">
        <div class="modal-content">
          <!-- Botón de cierre como cruz "X" -->
          <span @click="cerrarModalImagen" class="close-button">&times;</span>
          <h4>Sugerencia para tu tabla</h4>
          <p>{{ resumen }}</p>
          <div class="modal-body">
            <img :src="imagenUrl" alt="Imagen generada" style="max-width: 100%; border-radius: 10px;">
          </div>
          <p>Imagen producida con inteligencia artificial generativa</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMaridador from './HeaderMaridador.vue';

export default {
  components: {
    HeaderMaridador,
  },
  data() {
    return {
      resultado: this.$route.query.resultado, 
      ingredientes: this.$route.query.ingredientes,
      coronas: 0,
      calificacion: 0,
      rango: '',
      descripcion: '',
      resumen: '',
      sugerencias: [],
      mostrarRecomendaciones: false,
      mostrarImagenGenerada: false,
      cargandoImagen: false,
      imagenUrl: '',
      mensajeActual: 'Iniciando...', // Mensaje que se alternará
      imagenGenerada: false,
    };
  },
  mounted() {
    const ingredientes = this.$store.getters.ingredientes;
    const resultado = this.$store.getters.resultado;

    if (ingredientes) {
      this.ingredientes = ingredientes;
    }

    if (resultado) {
      this.coronas = resultado.coronas;
      this.rango = resultado.rango;
      this.calificacion = resultado.calificacion;
      this.resumen = resultado.resumen;
      this.descripcion = resultado.descripcion;
      this.sugerencias = resultado.sugerencias;
    }
  },
  methods: {
    editarMaridaje() {
      this.$router.push({ name: 'formulario' });
    },
    mostrarModalRecomendaciones() {
      this.mostrarRecomendaciones = true;
    },
    cerrarModalRecomendaciones() {
      this.mostrarRecomendaciones = false;
    },
    generarImagenIA() {
      this.cargandoImagen = true;
      this.imagenGenerada = false;

      const mensajes = [
        "Colocando el queso...",
        "Cortando ingredientes...",
        "Creando composición...",
        "Agregando detalles...",
        "Ajustando la luz...",
        "Últimos ajustes..."
      ];

      let index = 0;

      // Intervalo para alternar los mensajes
      const intervalId = setInterval(() => {
        this.mensajeActual = mensajes[index];
        index++;

        // Cuando se llegue al último mensaje, detener el intervalo
        if (index === mensajes.length) {
          clearInterval(intervalId);
        }
      }, 4000); // Cambia el mensaje cada 4 segundos

      // Simular la generación de imagen (llamada al backend)
      this.llamarApiGeneraImagen(this.ingredientes)
        .then((response) => {
          this.imagenUrl = `/imagenes/${response.imagen_url}`;
          this.mostrarImagenGenerada = true;
          this.imagenGenerada = true;
        })
        .finally(() => {
          this.cargandoImagen = false; // Detener el spinner
        })
        .catch((error) => {
          console.error("Error generando imagen:", error);
        });
    },
    llamarApiGeneraImagen(ingredientes) {
      return fetch(`${process.env.VUE_APP_BACKEND_URL}/genera-imagen`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ingredientes)
      }).then(response => response.json());
    },
    cerrarModalImagen() {
      this.mostrarImagenGenerada = false;
    }
  }
};
</script>

<style scoped>
h1 {
  color: #f1c40f;
}

.coronas {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.coronas-icon {
  font-size: 30px;
  margin: 0 5px;
}

/* Enlace estilizado para recomendaciones */
.recomendaciones-link {
  font-size: 14px;
  color: #a8946b;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;
}

.recomendaciones-link:hover {
  color: #f1c40f;
}

/* Spinner del botón */
.spinner-border {
  margin-right: 5px;
}

/* Estilo del modal */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Fondo oscuro */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Alineado hacia arriba */
  overflow-y: auto; /* Permite scroll */
  padding: 20px; /* Añade espacio para el scroll */
}

.modal-content {
  background-color: #2c2c2c; /* Fondo gris oscuro */
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
  position: relative;
}

.modal-body {
  overflow-y: auto; /* Scroll dentro del modal */
  text-align: left; /* Alineación a la izquierda */
  padding: 10px;
}

ul {
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}

/* Botón de cierre en forma de cruz "X" */
.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
}

.close-button:hover {
  color: #ccc;
}
</style>
